/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
        path: "/dashboard",
        name: "Dashboard",
        view: "Dashboard",
    },
    {
        path: "/buy-tickets",
        name: "BuyTickets",
        view: "BuyTickets",
    },
    {
        path: "/payment",
        name: "Payment",
        view: "Payment",
    },
    // {
    //     path: "/hello-world",
    //     name: "HelloWorld",
    //     view: "HelloWorld",
    // },
];

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app',{staticStyle:{"background-image":"url(https://utaw.blob.core.windows.net/dinner/5785/BarclaysBackground2.jpg)"}},[_c('router-view',{staticClass:"window-width",class:{
                'page-background': _vm.showPageBackground(),
                'space-top': _vm.addSpacingTop(),
            },staticStyle:{"z-index":"1"}}),_c('v-footer',{attrs:{"color":"rgba(0,0,0,0)"}},[_c('v-spacer'),_c('v-btn',{staticStyle:{"padding":"12px","margin":"16px"},attrs:{"color":"white"},on:{"click":_vm.openContactUsDialog}},[_vm._v(" Contact Us ")]),_c('v-spacer')],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.contactUsDialog),callback:function ($$v) {_vm.contactUsDialog=$$v},expression:"contactUsDialog"}},[_c('v-card',{staticClass:"pa-4"},[(_vm.messageSent)?_c('div',{staticClass:"mb-4"},[_c('v-card-title',{staticClass:"pb-2 px-0 pt-0"},[_vm._v("Thank You!")]),_c('div',{staticClass:"body-2"},[_vm._v(" Your message is very important to us. We will get back to you ASAP ")])],1):_c('div',[_c('v-card-title',{staticClass:"pb-2 pl-1 pt-0"},[_vm._v("Contact Us")]),_c('div',{staticClass:"body-2 pl-1"},[_vm._v(" Write a message to the dinner department. ")]),_c('v-text-field',{ref:"contactUsName",staticClass:"px-2",attrs:{"label":"Name","color":"rgb(35 72 81)","rules":[_vm.required]},model:{value:(_vm.contactUsName),callback:function ($$v) {_vm.contactUsName=$$v},expression:"contactUsName"}}),_c('v-text-field',{ref:"contactUsEmail",staticClass:"px-2",attrs:{"label":"Email","color":"rgb(35 72 81)","rules":_vm.emailRules},model:{value:(_vm.contactUsEmail),callback:function ($$v) {_vm.contactUsEmail=$$v},expression:"contactUsEmail"}}),_c('v-text-field',{ref:"contactUsPhone",staticClass:"px-2",attrs:{"label":"Phone Number","color":"rgb(35 72 81)","rules":[
                        (v) => !!v || 'A Valid Phone Number Is Required.',
                        (v) =>
                            !v ||
                            ([10, 11].includes(
                                v.replace(/-/g, '').length,
                            ) &&
                                /^\d+$/.test(v)) ||
                            'A Valid Phone Number Is Required.',
                    ]},model:{value:(_vm.contactUsPhone),callback:function ($$v) {_vm.contactUsPhone=$$v},expression:"contactUsPhone"}}),_c('v-textarea',{ref:"contactUsMessage",staticClass:"px-2",attrs:{"solo":"","color":"rgb(35 72 81)","label":"Message","rules":[_vm.required]},model:{value:(_vm.contactUsMessage),callback:function ($$v) {_vm.contactUsMessage=$$v},expression:"contactUsMessage"}}),(_vm.contactUsError)?_c('v-card-text',{staticClass:"pt-0 error--text"},[_vm._v(" "+_vm._s(_vm.contactUsError)+" ")]):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"default"},on:{"click":_vm.closeContactUs}},[_vm._v("Close")]),(!_vm.messageSent)?_c('v-btn',{staticStyle:{"background-color":"rgb(54 42 127) !important","color":"#fff !important"},attrs:{"loading":_vm.sending},on:{"click":_vm.contactUS}},[_vm._v("Send")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <v-app
            style="
                background-image: url(https://utaw.blob.core.windows.net/dinner/5785/BarclaysBackground2.jpg);
            "
        >
            <!-- <v-main>
      <HelloWorld/>
    </v-main> -->

            <router-view
                style="z-index: 1"
                class="window-width"
                v-bind:class="{
                    'page-background': showPageBackground(),
                    'space-top': addSpacingTop(),
                }"
            ></router-view>

            <v-footer color="rgba(0,0,0,0)">
                <v-spacer></v-spacer>

                <v-btn
                    color="white"
                    style="padding: 12px; margin: 16px"
                    @click="openContactUsDialog"
                >
                    Contact Us
                </v-btn>
                <v-spacer></v-spacer>
            </v-footer>
        </v-app>

        <v-dialog v-model="contactUsDialog" persistent max-width="500">
            <v-card class="pa-4">
                <div v-if="messageSent" class="mb-4">
                    <v-card-title class="pb-2 px-0 pt-0"
                        >Thank You!</v-card-title
                    >
                    <div class="body-2">
                        Your message is very important to us. We will get back
                        to you ASAP
                    </div>
                </div>
                <div v-else>
                    <v-card-title class="pb-2 pl-1 pt-0"
                        >Contact Us</v-card-title
                    >
                    <div class="body-2 pl-1">
                        Write a message to the dinner department.
                    </div>
                    <v-text-field
                        class="px-2"
                        label="Name"
                        v-model="contactUsName"
                        ref="contactUsName"
                        color="rgb(35 72 81)"
                        :rules="[required]"
                    ></v-text-field>
                    <v-text-field
                        class="px-2"
                        label="Email"
                        v-model="contactUsEmail"
                        color="rgb(35 72 81)"
                        :rules="emailRules"
                        ref="contactUsEmail"
                    ></v-text-field>
                    <v-text-field
                        class="px-2"
                        label="Phone Number"
                        color="rgb(35 72 81)"
                        v-model="contactUsPhone"
                        :rules="[
                            (v) => !!v || 'A Valid Phone Number Is Required.',
                            (v) =>
                                !v ||
                                ([10, 11].includes(
                                    v.replace(/-/g, '').length,
                                ) &&
                                    /^\d+$/.test(v)) ||
                                'A Valid Phone Number Is Required.',
                        ]"
                        ref="contactUsPhone"
                    ></v-text-field>
                    <v-textarea
                        class="px-2"
                        solo
                        v-model="contactUsMessage"
                        color="rgb(35 72 81)"
                        label="Message"
                        ref="contactUsMessage"
                        :rules="[required]"
                    ></v-textarea>
                    <v-card-text v-if="contactUsError" class="pt-0 error--text">
                        {{ contactUsError }}
                    </v-card-text>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="default" @click="closeContactUs">Close</v-btn>
                    <v-btn
                        v-if="!messageSent"
                        style="
                            background-color: rgb(54 42 127) !important;
                            color: #fff !important;
                        "
                        :loading="sending"
                        @click="contactUS"
                        >Send</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
/* eslint-disable */

//import HelloWorld from './components/HelloWorld';

export default {
    name: "App",

    // components: {
    //   HelloWorld,
    // },

    data: () => ({
        contactUsDialog: false,

        contactUsError: "",
        contactUsName: "",
        contactUsEmail: "",
        contactUsPhone: "",
        contactUsMessage: "",
        sending: false,
        messageSent: false,
        responsive: false,

        required: (value) => !!value || "Required.",

        emailRules: [
            (value) => {
                const pattern =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return !value || pattern.test(value) || "Invalid e-mail.";
            },
            (value) => !!value || "Required",
        ],
    }),
    created() {
        setTimeout(() => {
            window.location.reload();
        }, 180 * 60 * 1000);
    },
    mounted() {
        this.onResponsiveInverted();
        window.addEventListener("resize", this.onResponsiveInverted);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResponsiveInverted);
    },
    methods: {
        onResponsiveInverted() {
            if (window.innerWidth < 991) {
                this.responsive = true;
            } else {
                this.responsive = false;
            }
        },
        openContactUsDialog() {
            // if (
            //         this.$store.state.app.donor &&
            //         this.$store.state.app.donor.firstEnglish
            //     ) {
            //         this.contactUsName =
            //             this.$store.state.app.donor.firstEnglish +
            //             " " +
            //             this.$store.state.app.donor.lastEnglish;
            //     }

            //     if (this.$store.state.app.phone) {
            //         this.contactUsPhone = this.$store.state.app.phone;
            //     }

            //     if (
            //         this.$store.state.app.manual &&
            //         this.$store.state.app.manual.name
            //     ) {
            //         this.contactUsName = this.$store.state.app.manual.name;
            //         this.contactUsPhone = this.$store.state.app.manual.phone;
            //     }

            //     if (
            //         this.$store.state.app.paymentFields &&
            //         this.$store.state.app.paymentFields.email
            //     ) {
            //         this.contactUsEmail = this.$store.state.app.paymentFields.email;
            //     }

            this.contactUsDialog = true;
        },
        closeContactUs() {
            this.contactUsDialog = false;
            this.contactUsError = "";
            this.contactUsName = "";
            this.contactUsEmail = "";
            this.contactUsMessage = "";
            this.contactUsPhone = "";

            this.messageSent = false;
        },
        async contactUS() {
            if (
                ![
                    this.$refs.contactUsName.validate(true),
                    this.$refs.contactUsEmail.validate(true),
                    this.$refs.contactUsPhone.validate(true),
                    this.$refs.contactUsMessage.validate(true),
                ].every(Boolean)
            )
                return;

            let donorID = "";

            // if (this.$store.state.app.donor) {
            //     donorID =
            //         this.$store.state.app.donor.personID &&
            //         this.$store.state.app.donor.personID
            //             ? this.$store.state.app.donor.personID
            //             : null;
            // }

            try {
                this.sending = true;

                await this.$http.post(
                    "https://dinner-compaign.azurewebsites.net/api/account/contactUS",
                    {
                        name: this.contactUsName,
                        email: this.contactUsEmail,
                        //donorID: donorID,
                        phone: this.contactUsPhone,
                        category: "DINNER WEB",
                        message: this.contactUsMessage,
                    },
                );

                this.sending = false;
                this.messageSent = true;
            } catch (e) {
                this.sending = false;

                this.contactUsError = "Error sending message.";
            }
        },
        showPageBackground() {
            return ["/buy-tickets", "/payment"].includes(
                window.location.pathname,
            );
        },
        addSpacingTop() {
            return (
                !this.responsive &&
                !["/dashboard"].includes(window.location.pathname)
            );
        },
    },
};
</script>
<style>
.button {
    text-align: left !important;
    font: Medium 14px/20px Roboto;
    letter-spacing: 0 !important;
    color: #fff !important;
    background: rgb(32, 20, 71) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 2px #0000003d;
    border-radius: 2px;
    opacity: 1;
}
.page-background {
    background-color: #0e0030 !important;
    border: 2px solid #7869dd !important;
    border-radius: 10px !important;
}
.window-width {
    max-width: 960px !important;
}
.space-top {
    margin-top: 100px;
}
.space-top-mobile {
    margin-top: 50px;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import { sync } from "vuex-router-sync";
import router from "@/router";
import store from "@/store";
import axios from "axios";
//import { loadProgressBar } from "axios-progress-bar";
import VueProgressBar from "vue-progressbar";
import LogRocket from "logrocket";
import VueMask from "v-mask";

sync(store, router);

Vue.config.productionTip = false;

LogRocket.init("m61c5d/dinner-portal");

Vue.use(VueMask);
Vue.use(VueProgressBar, {
    color: "#029de5",
    failedColor: "red",
    height: "1px",
});

Vue.prototype.Progress = VueProgressBar;

const instance = axios.create({
    baseURL: window.location.origin.startsWith("http://localhost")
        ? "https://localhost:44306"
        : window.location.origin,
});

function errorHandler(error) {
    const text =
        error.response.data && error.response.data.Message
            ? error.response.data.Message
            : "An error has occurred";

    throw new Error(text);
}

instance.interceptors.response.use((response) => {
    return response ? response.data : response;
}, errorHandler);

//loadProgressBar(null, instance);

Vue.prototype.$http = instance;

new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

// https://vuex.vuejs.org/en/actions.html

export default {
    AUTH_REQUEST({ commit }) {
        return new Promise((resolve) => {
            const token = new Date();

            commit("AUTH_REQUEST");

            localStorage.setItem("user-token", token);

            commit("AUTH_SUCCESS", {
                token: token,
            });

            resolve(true);
        });
    },
    AUTH_LOGOUT({ commit }) {
        return new Promise((resolve) => {
            localStorage.removeItem("user-token");

            commit("AUTH_LOGOUT");

            resolve();
        });
    },
};

import { set, toggle } from "@/utils/vuex";

export default {
    setDrawer: set("drawer"),
    setImage: set("image"),
    setColor: set("color"),
    toggleDrawer: toggle("drawer"),

    setPayment(state, payment) {
        state.payment = payment;
    },
    setPaymentFields(state, paymentFields) {
        state.paymentFields = paymentFields;
    },
    setDonor(state, { donor, donorSettings, transactions, pin }) {
        state.donor = donor;
        state.donorSettings = donorSettings;
        state.transactions = transactions;
        state.pin = pin;
    },
    setBuyer(state, buyer) {
        state.buyer = buyer;
    },
    setManual(state, manual) {
        state.manual = manual;
    },
    setSeatSelection(state, seats) {
        state.seats = seats;
    },
};

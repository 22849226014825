/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from "vue";
import VueAnalytics from "vue-analytics";
import Router from "vue-router";
// import moment from "moment";
// import store from "../store";
// Routes
import paths from "./paths";

import Meta from "vue-meta";

// const dayAgo = date => {
//     var dayAgo = moment()
//         .subtract(1, "d")
//         .format("L");

//     return Date.parse(date) > Date.parse(dayAgo);
// };

// const isValidToken = token => {
//     return dayAgo(token);
// };

const ifAuthenticated = (to, from, next) => {
    next();

    return;
};

function route(path, view, name) {
    return {
        name: name || view,
        path,
        component: resovle => import(`@/components/${view}.vue`).then(resovle),
        beforeEnter: ifAuthenticated
    };
}

Vue.use(Router);

// Create a new router
const router = new Router({
    mode: "history",
    routes: paths
        .map(path => route(path.path, path.view, path.name))
        .concat([{ path: "*", redirect: "/dashboard" }]),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    }
});

Vue.use(Meta);

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.GOOGLE_ANALYTICS) {
    Vue.use(VueAnalytics, {
        id: process.env.GOOGLE_ANALYTICS,
        router,
        autoTracking: {
            page: process.env.NODE_ENV !== "development"
        }
    });
}

export default router;
